import React, { Component } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import "./style.css";

class Photos extends Component {
  photos = [
    {
      src: "/headshots/funny.jpeg",
      height: 3,
      width: 2,
    },
    {
      src: "/production_photos/holiday_inn.jpg",
      height: 5,
      width: 7,
    },
    {
      src: "/production_photos/seven_brides.jpg",
      height: 2,
      width: 3,
    },
    {
      src: "/production_photos/heart-10-web.jpg",
      height: 9,
      width: 8,
    },
    {
      src: "/production_photos/saigon.jpg",
      height: 2,
      width: 3,
    },
  ];

  render() {
    return (
      <div id="photos" className="content-section">
        <h1>Photos</h1>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry>
            {this.photos.map((photo) => (
              <img src={photo.src} alt="paige" />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    );
  }
}

export default Photos;
