import React, { Component } from 'react';

import './style.css';

class MenuItems extends Component {
  render() {
    return (
      <div className="menu-items">
        <img
          src="/signature.png"
          alt="Paige Faure"
          id="mobile-name"
          onClick={() => this.props.scrollToAndClose('#about')}
        />
        <span className="blurb">
          <i>Official Website</i>
        </span>
        <span
          className="menu-item"
          onClick={() => this.props.scrollToAndClose('#resume')}
        >
          Resume
        </span>
        <span
          className="menu-item"
          onClick={() => {
            this.props.scrollToAndClose('#videos');
          }}
        >
          Videos
        </span>
        <span
          className="menu-item"
          onClick={() => {
            this.props.scrollToAndClose('#photos');
          }}
        >
          Photos
        </span>
        <span
          className="menu-item"
          href="/contact"
          onClick={() => this.props.scrollToAndClose('#contact')}
        >
          Contact
        </span>
      </div>
    );
  }
}

export default MenuItems;
