import React, { Component } from 'react';

import './style.css';

class Videos extends Component {
  render() {
    return (
      <div id="videos" className="content-section">
        <h1>Videos</h1>
        <div class="video">
          <div class="iframe-container">
            <iframe
              title="Glow"
              src="https://www.youtube.com/embed/qw6SQ3oMh2M?si=TpJHGkD14I95ciye"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div class="iframe-container">
            <iframe
              title="Sheila's monologue"
              src="https://www.youtube.com/embed/szxJ-lxEwE4"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
           <div class="iframe-container">
            <iframe
              title="I Happen To Like New York"
              src="https://www.youtube.com/embed/tLVLY3APhXY"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div class="iframe-container">
            <iframe
              title="At the ballet"
              src="https://www.youtube.com/embed/jiI7ZXMOxPw"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div class="iframe-container">
            <iframe
              title="Ladies Who Lunch"
              src="https://www.youtube.com/embed/YrjLOGCaNtw?si=PscpvairwWqqNvBo"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>    
        </div>
      </div>
    );
  }
}

export default Videos;
