import React, { Component } from 'react';
import scrollToElement from 'scroll-to-element';
import { slide as Menu } from 'react-burger-menu';

import MenuItems from '../MenuItems';

import './style.css';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false
    };
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  scrollToAndClose = loc => {
    const config = {};
    if (loc !== '#about') {
      config.offset = -68;
    }
    scrollToElement(loc, config);
    this.closeMenu();
  };

  render() {
    return (
      <div id="header">
        <div className="header-left">
          <img
            src="/signature.png"
            alt="Paige Faure"
            id="mobile-name"
            onClick={() => this.scrollToAndClose('#about')}
          />
          <span className="header-blurb">
            <i>Official</i>
            <i>Website</i>
          </span>
        </div>
        <div className="header-right">
          <span
            className="header-item"
            onClick={() => this.scrollToAndClose('#resume')}
          >
            Resume
          </span>
          <span
            className="header-item"
            onClick={() => {
              this.scrollToAndClose('#videos');
            }}
          >
            Videos
          </span>
          <span
            className="header-item"
            onClick={() => {
              this.scrollToAndClose('#photos');
            }}
          >
            Photos
          </span>
          <span
            className="header-item"
            href="/contact"
            onClick={() => this.scrollToAndClose('#contact')}
          >
            Contact
          </span>
          <a
            href="https://www.instagram.com/paigefaure/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/icons/instagram.svg"
              alt="Instagram"
              style={{ height: '28px', margin: '3px 10px 0 4px' }}
            />
          </a>
        </div>
        <Menu
          right
          pageWrapId={'page-wrap'}
          outerContainerId={'outer-container'}
          isOpen={this.state.menuOpen}
          width={'100%'}
          customBurgerIcon={<img src="icons/burger.svg" alt="☰" />}
          onStateChange={state => this.handleStateChange(state)}
        >
          <MenuItems scrollToAndClose={this.scrollToAndClose} />
        </Menu>
      </div>
    );
  }
}

export default Header;
