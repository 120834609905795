import React, { Component } from 'react';

import './style.css';

class Footer extends Component {
  render() {
    return (
      <div id="footer" className="content-section">
        Copyright 2019
      </div>
    );
  }
}

export default Footer;
