import React, { Component } from 'react';

import './style.css';

class Info extends Component {
  render() {
    return (
      <div id="info" className="content-section">
        <h1>Paige Faure</h1>
        <p>Paige is a versatile, true triple-threat performer based in NYC.</p>
        <p>
          A creative and compelling actor, Paige approaches her work with humor, depth, and curiosity.
        </p>
      </div>
    );
  }
}

export default Info;
