import React, { Component } from 'react';

class ResumeItem extends Component {
  render() {
    const { title, role, location, director, image, link } = this.props;
    return (
      <div className="resume-item">
        <div className="image-container">
          {image && (
            <a href={link} target="_blank" rel="noopener noreferrer">
              <img src={image} alt={title} />
            </a>
          )}
        </div>
        <div className="resume-text">
          <div>
            <h3 style={{ marginTop: 0 }}>{title}</h3>
          </div>
          <div>
            <div className="role">{role}</div>
            <div>{director}</div>
            <div>{location}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResumeItem;
