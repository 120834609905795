import React, { Component } from 'react';
import Resume from './components/Resume';
import About from './components/About';

import './App.css';
import Photos from './components/Photos';
import Videos from './components/Videos';
import Header from './components/Header';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Info from './components/Info';

class App extends Component {
  render() {
    return (
      <div id="outer-container">
        <Header />
        <main id="page-wrap">
          <div id="main-content">
            <About />
            <Info />
            <Resume />
            <Videos />
            <Photos />
            <Contact />
            <Footer />
          </div>
        </main>
      </div>
    );
  }
}

export default App;
