import React, { Component } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "./style.css";

class About extends Component {
  render() {
    return (
      <div id="about" className="content-section">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src="/headshots/paige_1.jpg" alt="headshot" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/headshots/paige_2.jpg" alt="headshot" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/headshots/paige_3.jpg" alt="headshot" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/headshots/paige_4.jpg" alt="headshot" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/headshots/paige_5.jpg" alt="headshot" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/headshots/paige_6.jpg" alt="headshot" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/headshots/paige_7.jpg" alt="headshot" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/headshots/paige_8.jpg" alt="headshot" />
          </SwiperSlide>
        </Swiper>
      </div>
    );
  }
}

export default About;
