import React, { Component } from 'react';

import './style.css';

class Contact extends Component {
  render() {
    return (
      <div id="contact" className="content-section">
        <h1>Contact</h1>
        <div className="sections">
          <div className="casual-section">
            <h2>Say Hi!</h2>
            <form
              method="POST"
              action="https://formspree.io/paigefaure@gmail.com"
            >
              <span>Name *</span>
              <input required type="text" name="name" />
              <span>Email Address *</span>
              <input required type="email" name="_replyto" />
              <span>Message *</span>
              <textarea required name="message" />
              <button type="submit">Submit</button>
            </form>
          </div>
          <div className="professional-section">
            <h2>For Professional Inquiries</h2>
            <div>Contact</div>
            <br />
            <div>Daniel Hoff Agency</div>
            <div>attn: Dustin Flores</div>
            <img src="/daniel_hoff_logo.jpeg" alt="Daniel Hoff Agency" />
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
