import React, { Component } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

import "./style.css";
import ResumeItem from "./resumeItem";

class Resume extends Component {
  broadwayItems = [
    {
      title: "A Beautiful Noise",
      role: "Standby: Marcia, Jaye, Ellie/Rose",
      location: "Broadhurst Theatre",
      director: "Dir. Michael Mayer",
      image: "/playbills/beautiful-noise.jpeg",
      link: "https://www.playbill.com/production/a-beautiful-noise-the-neil-diamond-musical-broadway-broadhurst-theatre-2022",
    },
    {
      title: "Company",
      role: "Standby: Bobbie, Sarah, Susan, Jenny",
      location: "Bernard Jacobs Theatre",
      director: "Dir. Marianne Elliott",
      image: "/playbills/company.jpeg",
      link: "https://playbill.com/production/companybernard-b-jacobs-theatre-2021-2022",
    },
    {
      title: "Rodgers & Hammerstein’s Cinderella",
      role: "Cinderella",
      location: "Broadway Theatre",
      director: "Dir. Mark Brokaw",
      image: "/playbills/cinderella.jpeg",
      link: "http://www.playbill.com/production/cinderella-broadway-theatre-vault-0000013978",
    },
    {
      title: "Miss Saigon (OBC)",
      role: "u/s Ellen",
      location: "Broadway Theatre",
      director: "Dir. Laurence Connor",
      image: "/playbills/miss-saigon.jpeg",
      link: "http://www.playbill.com/production/miss-saigon-broadway-theatre-2016-2017",
    },
    {
      title: "Bullets Over Broadway (OBC)",
      role: "u/s Ellen",
      location: "St. James Theatre",
      director: "Dir. Susan Stroman",
      image: "/playbills/bullets-over-broadway.jpeg",
      link: "http://www.playbill.com/production/bullets-over-broadway-st-james-theatre-vault-0000014008",
    },
    {
      title: "How To Succeed... (OBC)",
      role: "Hedy La Rue (replacement)",
      location: "Al Hirschfeld Theatre",
      director: "Dir. Rob Ashford",
      image: "/playbills/how-to-succeed.jpeg",
      link: "http://www.playbill.com/production/how-to-succeed-in-business-without-really-trying-al-hirschfeld-theatre-vault-0000013723",
    },
  ];
  offBroadwayItems = [
    {
      title: "A Taste of Things to Come",
      role: "Joan",
      location: "The York Theatre",
      director: "Dir. Lorin Latarro",
    },
  ];
  nationalTourItems = [
    {
      title: "R&H's Cinderella",
      role: "Cinderella",
      location: "1st National Tour",
      director: "Dir. Mark Brokaw",
    },
    {
      title: "A Chorus Line",
      role: "Vicki; Sheila/Maggie/Diana/Judy u/s (perf.)",
      location: "On The Line Productions",
      director: "Dir. Bob Avian",
    },
    {
      title: "Chitty Chitty Bang Bang",
      role: "Truly Scrumptious u/s (perf.)",
      location: "Big League Theatricals",
      director: "Dir. Ray Roderick Chor. JoAnn Hunter",
    },
    {
      title: "Little Women",
      role: "Jo March",
      location: "Windwood Theatricals",
    },
    {
      title: "Aida",
      role: "Amneris u/s (perf.)",
      location: "Big League Theatricals",
    },
  ];
  regionalTheatreItems = [
    {
      title: "Holiday Inn",
      role: "Lila",
      location: "Paper Mill Playhouse",
    },
    {
      title: "The Heart of Rock and Roll",
      role: "Paige",
      location: "The Old Globe",
    },
    {
      title: "Seven Brides for Seven Brothers",
      role: "Milly",
      location: "Sacramento Music Circus",
    },
    {
      title: "Chess",
      role: "Ensemble",
      location: "Kennedy Center",
      director: "Dir. Michael Mayer",
    },
    {
      title: "Nice Work If You Can Get It",
      role: "Eileen",
      location: "Sacramento Music Circus",
    },
    {
      title: "From Here to Eternity (US Premiere)",
      role: "Lorene",
      location: "Fingler Lakes MT Festival",
    },
    {
      title: "Honeymoon in Vegas",
      role: "Ensemble, u/s Betsy",
      location: "Paper Mill Playhouse",
    },
    {
      title: "Drowsy Chaperone",
      role: "Janet",
      location: "Noth Carolina Theatre",
    },
    {
      title: "Cabaret",
      role: "Sally",
      location: "Merry-Go-Round Playhouse",
      director: "Dir. Brett Smock",
    },
    {
      title: "Robin and the Seven Hoods",
      role: "Jetsetter, u/s Alana O'Dell",
      location: "Old Globe Theater",
      director: "Dir. Casey Nicholaw",
    },
    {
      title: "On The Town",
      role: "Claire De Loone u/s",
      location: "Paper Mill Playhouse",
      director: "Dir. Bill Berry / Chor. Patti Colombo",
    },
  ];
  televisionItems = [
    {
      title: "Search Party",
      role: "Pregnant Woman",
      location: "TBS",
      director: "Dir. Ryan McFaul",
    },
  ];
  render() {
    return (
      <div id="resume" className="content-section">
        <h1>
          Resume
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="paige_faure_resume.pdf"
          >
            <img
              href="paige_faure_resume.pdf"
              src="/icons/link.svg"
              alt="link"
              className="link-icon"
            />
          </a>
        </h1>
        <Accordion allowMultipleExpanded={true}>
          <AccordionItem dangerouslySetExpanded={true}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h2>Broadway</h2>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="resume-group">
                {this.broadwayItems.map((item) => (
                  <ResumeItem
                    key={item.title}
                    title={item.title}
                    role={item.role}
                    location={item.location}
                    director={item.director}
                    image={item.image}
                    link={item.link}
                  />
                ))}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h2>Off Broadway</h2>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="resume-group">
                {this.offBroadwayItems.map((item) => (
                  <ResumeItem
                    key={item.title}
                    title={item.title}
                    role={item.role}
                    location={item.location}
                    director={item.director}
                    image={item.image}
                    link={item.link}
                  />
                ))}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h2>National Tours</h2>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="resume-group">
                {this.nationalTourItems.map((item) => (
                  <ResumeItem
                    key={item.title}
                    title={item.title}
                    role={item.role}
                    location={item.location}
                    director={item.director}
                    image={item.image}
                    link={item.link}
                  />
                ))}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h2>Regional Theatre</h2>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="resume-group">
                {this.regionalTheatreItems.map((item) => (
                  <ResumeItem
                    key={item.title}
                    title={item.title}
                    role={item.role}
                    location={item.location}
                    director={item.director}
                    image={item.image}
                    link={item.link}
                  />
                ))}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h2>Television</h2>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="resume-group">
                {this.televisionItems.map((item) => (
                  <ResumeItem
                    key={item.title}
                    title={item.title}
                    role={item.role}
                    location={item.location}
                    director={item.director}
                    image={item.image}
                    link={item.link}
                  />
                ))}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    );
  }
}

export default Resume;
